import JSX from '../../jsx.compiler';
import {PipeType} from '../../types/enums/pipe-type.enum';

export const DISPUTES_LAYOUT = {
	table: {
		tableColumns: [
			{
				key: '/createdOn',
				label: 'DISPUTE_DATE',
				pipe: [PipeType.Date]
			},
			{
				key: '/evidenceDue',
				label: 'EVIDENCE_DUE',
				pipe: [PipeType.Date]
			},
			{
				key: '/location',
				label: 'MERCHANT',
				populate: {
					collection: 'locations',
					displayKey: 'name',
				}
			},
			{
				key: '/cardholder',
				label: 'CARDHOLDER',
			},
			{
				key: '/reason',
				label: 'REASON'
			},
			{
				key: '/status',
				label: 'STATUS',
				pipe: [
					PipeType.Custom,
					PipeType.Sanitize
				],
				pipeArguments: {
					0: v => JSX(<app-e-status status={v.toLowerCase()} />)
				}
			},
			{
				key: '/amount',
				label: 'AMOUNT',
				pipe: [PipeType.Custom, PipeType.Sanitize],
				pipeArguments: {
					0: (v, r) => JSX(<app-e-amount amount={v} currency={r.invoiceChargeCurrency} />)
				}
			}
		]
	}
};